export var attributionVersions = {
  "CC0": {
    url: "https://creativecommons.org/share-your-work/public-domain/cc0/"
  },
  "CC0 1.0": {
    url: "https://creativecommons.org/publicdomain/zero/1.0/"
  },
  "CC BY 2.0": {
    url: "https://creativecommons.org/licenses/by/2.0/"
  },
  "CC BY-SA 2.0": {
    url: "https://creativecommons.org/licenses/by-sa/2.0/"
  },
  "CC BY 2.5": {
    url: "https://creativecommons.org/licenses/by/2.5/"
  },
  "CC BY-SA 2.5": {
    url: "https://creativecommons.org/licenses/by-sa/2.5/"
  },
  "CC BY 3.0": {
    url: "https://creativecommons.org/licenses/by/3.0/"
  },
  "CC BY-SA 3.0": {
    url: "https://creativecommons.org/licenses/by-sa/3.0/"
  },
  "CC BY 4.0": {
    url: "https://creativecommons.org/licenses/by/4.0/"
  },
  "CC BY-SA 4.0": {
    url: "https://creativecommons.org/licenses/by-sa/4.0/"
  }
};
export var getLinkToAttributionPage = function (attributionVersion) {
  return attributionVersions[attributionVersion];
};